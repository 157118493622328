import React from "react";

export default function FaqItem({faqId,header,text}) {
  return (
    <div className="accordion-item" key={faqId}>
      <h2 className="accordion-header" id={"heading"+faqId}>
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#collapse"+faqId}
          aria-expanded="false"
          aria-controls={"collapse"+faqId}
        >
          {header}
        </button>
      </h2>
      <div
        id={"collapse"+faqId}
        key={"collapse"+faqId}
        className="accordion-collapse collapse"
        aria-labelledby={"heading"+faqId}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body" key={"text"+faqId} dangerouslySetInnerHTML={ { __html: text } } />
      </div>
    </div>
  );
};
